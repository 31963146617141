import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Alert, useTheme, useMediaQuery } from '@mui/material';

const ForgotPasswordDialog = ({ open, onClose }) => {
    const [phone, setPhone] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleRecoverPassword = async () => {
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-change-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "recover_password",
                        phone: phone // assuming phone number is entered without country code
                    }
                })
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setAlertVisible(true);
                setIsButtonDisabled(true);
                setTimeout(() => {
                    setIsButtonDisabled(false);
                    setAlertVisible(false);
                    onClose(); // Close the dialog after 5 seconds when the alert is no longer visible
                }, 5000); // Alert disappears and dialog closes after 5 seconds
            }
        } catch (error) {
            console.error("Error during password recovery:", error);
        }
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            sx={{
                '& .MuiDialog-paper': { // Targeting the Dialog's paper component
                    width: '100%',       // Full width on smaller screens
                    maxWidth: '400px',   // Max width on larger screens
                },
            }}
        >
            <DialogTitle>Recuperar Contraseña</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Número de teléfono (10 dígitos)"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            margin="normal"
                        />
                    </Grid>
                    {alertVisible && (
                        <Grid item xs={12}>
                            <Alert severity="success">
                                Se ha enviado una nueva contraseña a tu teléfono.
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleRecoverPassword} color="secondary" variant="contained" disabled={isButtonDisabled}>
                    Recuperar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ForgotPasswordDialog;
