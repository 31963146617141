import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // Added useParams to imports
import { Accordion, Alert, Button, Card, Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import Footer from '../Footer/Footer';

import logo from '../../images/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../../AuthContext'; // Adjust path based on your directory structure

const URL = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-retrieve';
const ERROR_MESSAGE = "¡Vaya! No pudimos encontrar información sobre la mascota que estás buscando. Por favor, asegúrate de que el enlace es correcto.";

const PetDetailedInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const phoneOwner = searchParams.get('phone');
    const [pets, setPets] = useState([]);

    useEffect(() => {
        retrievePetsData();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);


    const handleLogout = () => {
        console.log('Logging out...');
        setIsAuthenticated(false); // Set authentication state to false
        navigate('/login', { replace: true });
    };


    const retrievePetsData = async () => {
        try {
            const bodyPayLoad = JSON.stringify({
                queryStringParameters: {
                    action: 'get_pets_from_phone',
                    phone: phoneOwner
                }
            });

            console.log("[retrievePetsData] Calling get pets from phone with payload:", bodyPayLoad);

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: bodyPayLoad
            });

            const data = await response.json();
            console.log("[retrievePetsData] Raw Response Data:", data);

            const parsedData = data && typeof data.body === "string" ? JSON.parse(data.body) : null;
            console.log("[retrievePetsData] Parsed Response:", parsedData);

            if (parsedData && parsedData.pets) {
                console.log("[retrievePetsData] Pets to be set:", parsedData.pets);
                setPets(parsedData.pets);
            } else {
                console.log("[retrievePetsData] No pets found in parsed data. Throwing error.");
                throw new Error();
            }
        } catch (error) {
            console.error("[retrievePetsData] Error:", error);
            setErrorMsg(ERROR_MESSAGE);
        } finally {
            console.log("[retrievePetsData] Setting loading to false.");
            setLoading(false);
        }
    };

    const goToPetDetails = () => {
        console.log("Same");
    }

    const goToPerfil = () => {
        navigate(`/profile?phone=${phoneOwner}`);
    }

    return (
        <Container style={{ margin: 0 }}>
            <Navbar bg="light" expand="lg" sticky="top" style={{ padding: '10px 30px', borderBottom: '1px solid #e1e1e1' }}>
                <Navbar.Brand>
                    <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="Logo" />
                    <span className="ml-2">ID Lover</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Button
                            variant="outline-primary"
                            className="mr-3 rounded-pill"
                            style={{ padding: '8px 20px', fontSize: '15px', transition: 'background-color 0.3s' }}
                            onClick={goToPerfil} // Added the onClick event to the button
                        >
                            Perfil
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="mr-3 rounded-pill"
                            style={{ padding: '8px 20px', fontSize: '15px', transition: 'background-color 0.3s' }}
                            onClick={goToPetDetails} // Added the onClick event to the button
                        >
                            Mi amig@
                        </Button>
                        <Button variant="outline-danger" onClick={handleLogout} className="rounded-pill" style={{ padding: '8px 20px', fontSize: '15px', transition: 'background-color 0.3s' }}>
                            Salir
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {loading ? <LoadingComponent /> : (!pets || errorMsg) ? <ErrorComponent errorMsg={errorMsg} /> : <PetProfileDisplay pets={pets} />}
            <Footer />
        </Container>

    );
};

const LoadingComponent = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner animation="border" role="status"></Spinner>
    </div>
);

const ErrorComponent = ({ errorMsg }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="warning" style={{ textAlign: 'center', maxWidth: '400px' }}>
            <Alert.Heading>¡Oh no!</Alert.Heading>
            <p>{errorMsg}</p>
        </Alert>
    </div>
);

const PetProfileDisplay = ({ pets }) => {

    const [activeKey, setActiveKey] = useState("0");
    return (
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
            {pets.map((petData, index) => (
                <Card key={petData.petId} style={{ margin: '20px 0' }}>
                    <Card.Header>
                        <Accordion.Header onClick={() => {
                            if (activeKey === index.toString()) {
                                setActiveKey("-1");
                            } else {
                                setActiveKey(index.toString());
                            }
                        }}>
                            {petData.name}
                        </Accordion.Header>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                            <div id="card" style={{ maxWidth: '500px', margin: 'auto', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', padding: '20px', backgroundColor: '#ffffff' }}>
                                <div className="personal" style={{ textAlign: 'center' }}>
                                    <div className="avatar" style={{ marginBottom: '15px' }}>
                                        <img src={petData.imageUrl} alt={petData.name} style={{ width: '150px', height: '150px', borderRadius: '75px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} />
                                    </div>
                                    <div className="nameHolder">
                                        <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', color: '#333' }}>¡Hola! Soy {petData.name}</h1>
                                        <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#555' }}>Soy un {petData.race}</h2>
                                    </div>
                                </div>
                                <div className="info" style={{ padding: '15px 0', borderTop: '1px solid #eee', borderBottom: '1px solid #eee', margin: '10px 0' }}>
                                    <p><strong>Edad:</strong> Tengo {petData.age}</p>
                                    <p><strong>Descripción:</strong> {petData.description}</p>
                                    {petData.location && <p><strong>Ubicación:</strong> Vivo en {petData.location}</p>}
                                </div>
                                <div className="info" style={{ borderBottom: '1px solid #eee' }}>
                                    <p><strong>Contactar a mi dueño:</strong> <i className="fas fa-phone-alt"></i> {petData.phone}</p>
                                    <p><strong>Otro teléfono:</strong> <i className="fas fa-phone-alt"></i> {petData.secondaryPhone}</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    );
};

export default PetDetailedInfo;
