import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Card, Container, Form, Nav, Navbar, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Added useNavigate to imports
import { useAuth } from '../../AuthContext'; // Adjust path based on your directory structure
import logo from '../../images/logo.png'; // Make sure the path is correct
import Footer from '../Footer/Footer';
import axios from 'axios';  // Assuming you're using axios for HTTP requests
import './Profile.css';
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import gigi from '../../images/gigi2.jpeg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MemoizedFilePond from '../FilePond/MemorizedFilePond';
import SupportCard from '../Support/SupportCard';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HealingIcon from '@mui/icons-material/Healing';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import {
    IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useMediaQuery, useTheme } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VaccinationRecordComponent from '../Record/VaccinationRecordComponent';
import { Autocomplete } from '@mui/material';

console.log(Accordion, Card, Button, Spinner, Form);

const URL = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/get-pets-from-phone';
const ERROR_MESSAGE = "¡Vaya! No pudimos encontrar información sobre la mascota que estás buscando. Por favor, asegúrate de que el enlace es correcto.";
const commonBreeds = [
    "Labrador Retriever",
    "Pastor Alemán",
    "Golden Retriever",
    "Bulldog",
    "Beagle",
    "Poodle",
    "Rottweiler",
    "Boxer",
    "Dachshund",
    "Siberian Husky",
    "Yorkshire Terrier",
    "Pitbull",
    "Chihuahua",
    "Border Collie",
    "Pastor Australiano",
    "Cocker Spaniel",
    "Bichon Frise",
    "Doberman",
    "Shih Tzu",
    "Gran Danés",
    "Mastín Napolitano",
    "San Bernardo",
    "Cavalier King Charles Spaniel",
    "Shar Pei",
    "Akita Inu",
    "Bulldog Francés",
    "Dálmata",
    "Galgo",
    "Jack Russell Terrier",
    "Maltese",
    "Pomerania",
    "Samoyedo",
    "Shiba Inu",
    "Staffordshire Bull Terrier",
    "Weimaraner",
    "West Highland White Terrier",
    "Basset Hound",
    "Boston Terrier",
    "Braco Alemán",
    "Bull Terrier",
    "Cane Corso",
    "Carlino",
    "Chow Chow",
    "Fox Terrier",
    "Pinscher",
    "Pointer",
    "Setter Irlandés",
    "Teckel"
    // ... you can add more breeds as needed
];

const responsiveImageStyle = {
    width: '100%', // Make image width responsive
    height: 'auto', // Maintain aspect ratio
    maxHeight: '300px', // Limit the maximum height
    borderRadius: '8px', // Optional: add a border radius
    margin: '10px 0' // Margin for spacing
};

const Profile = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phoneOwner = searchParams.get('phone');
    const userId = searchParams.get('userId');
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [pets, setPets] = useState([]);
    const [petData, setPetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showShow, setShowShow] = useState(false);
    const { collapseSidebar } = useProSidebar();
    const [activeSection, setActiveSection] = useState('inicio');
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        if (activeSection === 'inicio') {
            retrievePetsData();
        }
    }, [activeSection]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);


    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const retrievePetsData = async () => {
        try {
            const bodyPayLoad = JSON.stringify({
                queryStringParameters: {
                    action: 'get_pets_from_phone',
                    phone: phoneOwner
                }
            });

            console.log("[retrievePetsData] Calling get pets from phone with payload:", bodyPayLoad);

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: bodyPayLoad
            });

            const data = await response.json();
            console.log("[retrievePetsData] Raw Response Data:", data);

            const parsedData = data && typeof data.body === "string" ? JSON.parse(data.body) : null;
            console.log("[retrievePetsData] Parsed Response:", parsedData);

            if (parsedData && parsedData.pets) {
                console.log("[retrievePetsData] Pets to be set:", parsedData.pets);
                setPets(parsedData.pets);
            } else {
                console.log("[retrievePetsData] No pets found in parsed data. Throwing error.");
                throw new Error();
            }
        } catch (error) {
            console.error("[retrievePetsData] Error:", error);
            setErrorMsg(ERROR_MESSAGE);
        } finally {
            console.log("[retrievePetsData] Setting loading to false.");
            setLoading(false);
        }
    };


    const goToPetDetails = () => {
        navigate(`/petDetailedInfo?phone=${phoneOwner}`);
    }

    const goToPerfil = () => {
        console.log("Same");
    }


    const handleLogout = () => {
        console.log('Logging out...');
        setIsAuthenticated(false); // Set authentication state to false
        navigate('/login', { replace: true });
    };

    return (
        <div id="app" style={({ height: "100vh" }, { display: "flex" })}>
            <Sidebar style={{ height: "100vh" }}>
                <Menu>
                    <MenuItem
                        icon={<MenuOutlinedIcon />}
                        onClick={() => {
                            collapseSidebar();
                        }}
                        style={{ textAlign: "center", marginTop: '20px', marginBottom: '20px' }}
                    >
                        <Avatar
                            src={logo}
                            sx={{
                                width: isSmallScreen ? 40 : 80,
                                height: isSmallScreen ? 40 : 80,

                            }}
                        />

                    </MenuItem>

                    <MenuItem onClick={() => handleSectionChange('inicio')} icon={<HomeOutlinedIcon />}>Inicio</MenuItem>
                    <MenuItem onClick={() => handleSectionChange('salud')} icon={<MedicalServicesIcon />}>Salud</MenuItem>
                    <MenuItem onClick={() => handleSectionChange('settings')} icon={<SettingsIcon />}>
                        Configuración
                    </MenuItem>
                    <MenuItem onClick={() => handleSectionChange('soporte')} icon={<HelpOutlineOutlinedIcon />}>Soporte</MenuItem>
                    <MenuItem onClick={handleLogout} icon={<ExitToAppIcon />}>
                        Cerrar sesión
                    </MenuItem>

                </Menu>
            </Sidebar>
            <main>
                {activeSection === 'inicio' && (
                    loading ? <LoadingComponent /> : (!pets.length || errorMsg) ? <ErrorComponent errorMsg={errorMsg} /> : <PetProfileEditor pets={pets} />
                )}
                {activeSection === 'settings' && (
                    <PasswordChangeComponent />
                    // Add your PasswordChangeComponent here. You can also pass any required props.
                )}
                {activeSection === 'salud' && (
                    <VaccinationRecordComponent userId={userId} phoneOwner={phoneOwner} />
                    // Add your PasswordChangeComponent here. You can also pass any required props.
                )}
                {activeSection === 'soporte' && (
                    <SupportCard />
                    // Add your PasswordChangeComponent here. You can also pass any required props.
                )}
                {/* You can add more conditional rendering for other sections */}
            </main>

        </div>
    );
};

const LoadingComponent = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner animation="border" role="status"></Spinner>
    </div>
);

const ErrorComponent = ({ errorMsg }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="warning" style={{ textAlign: 'center', maxWidth: '400px' }}>
            <Alert.Heading>¡Oh no!</Alert.Heading>
            <p>{errorMsg}</p>
        </Alert>
    </div>
);

const PetProfileEditor = ({ pets }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("0");
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedFileRuac, setUploadedFileRuac] = useState(null);

    const [editedData, setEditedData] = useState([]);
    const [locationEnabled, setLocationEnabled] = useState(false); // Default to false
    const [alertVisible, setAlertVisible] = useState(false);
    const userId = searchParams.get('userId');

    const handleInputChange = (petIndex, e) => {
        const { name, value } = e.target;
        setEditedData(prev => {
            const updatedPets = [...prev];
            updatedPets[petIndex] = { ...updatedPets[petIndex], [name]: value };
            console.log('Updated pet ', updatedPets);
            return updatedPets;
        });
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Helper function to convert file to Base64
    const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    const handleFileChange = (file, petIndex, fileType = 'image') => {
        console.log("[handleFileChange] Selected file:", file);
        if(fileType === 'image'){
            setUploadedFile(file);
        }
        if(fileType === 'ruac'){
            setUploadedFileRuac(file);
        }

        if (file) {
            const reader = new FileReader();
            reader.onloadend = function () {
                console.log("[handleFileChange] File read successfully. Setting data for pet index:", petIndex);
                setEditedData(prev => {
                    const updatedPets = [...prev];
                    if (fileType === 'image') {
                        updatedPets[petIndex] = { ...updatedPets[petIndex], imageUrl: reader.result, imageData: reader.result.split(",")[1] };
                    } else if (fileType === 'ruac') {
                        updatedPets[petIndex] = { ...updatedPets[petIndex], ruacUrl: reader.result, ruacData: reader.result.split(",")[1] };
                    }
                    return updatedPets;
                });
            };
            reader.onerror = function () {
                console.error("[handleFileChange] Error reading the file:", reader.error);
            };
            reader.readAsDataURL(file);
        } else {
            console.warn("[handleFileChange] No file selected.");
        }
    };

    const handleSubmit = async (petIndex, petIdOrigin, petPhone, e) => {
        e.preventDefault();
        console.log("[handleSubmit] Submitting form for pet index:", petIndex);
        if (!petIdOrigin) {
            console.error("[handleSubmit] PetIdOrigin is undefined. Exiting.");
            return;
        }
        setIsLoading(true);

        const petData = editedData[petIndex];
        console.log("[handleSubmit] Pet data:", petData);
        console.log("[handleSubmit] PetId:", petIdOrigin);
        console.log("[handleSubmit] userId:", userId);

        if (uploadedFile) {
            console.log('Contains image data front end');
            console.log(uploadedFile);
            petData.imageData = await convertFileToBase64(uploadedFile);
        }
        if (uploadedFileRuac) {
            console.log('Contains image ruac data front end');
            console.log(uploadedFileRuac);
            petData.ruacData = await convertFileToBase64(uploadedFileRuac);
        }


        const { petId, imageUrl, ...dataWithoutPetId } = petData;

        // Inserting the id of  the user
        petData.userId = userId;
        console.log("userId to be sent:", petData.userId);
        if (petData.imageData) {
            dataWithoutPetId.imageData = petData.imageData;
        }

        const requestBody = {
            queryStringParameters: {
                action: "update",
                petId: petIdOrigin,
                phone: petPhone,
                userId: userId
            },
            body: JSON.stringify(dataWithoutPetId)
        };

        console.log("[handleSubmit] Request body prepared:", requestBody);

        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            const result = await response.json();
            console.log("[handleSubmit] Response received:", result);
            if (result.statusCode && result.statusCode == 200) {
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 3000); // Alert disappears and dialog closes after 5 seconds            }
            }
            // Handle the result if needed
            if (result.errorMessage) {
                setError(true);
            }

        } catch (error) {
            console.error("[handleSubmit] Error updating profile:", error);
            setError(true);

        } finally {
            console.log("[handleSubmit] Setting loading to false.");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setEditedData(pets.map(pet => ({ ...pet })));
    }, [pets]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(false);
            }, 5000); // 5000 milliseconds = 5 seconds

            return () => clearTimeout(timer); // Clear the timer when component is unmounted or error changes
        }
    }, [error]);
    return (
        <Box sx={{ mt: 4, ml: 2 }}>
            <Typography
                variant="h4"
                gutterBottom
                component="div"
                sx={{
                    mb: 3,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', sm: '1rem', md: '1.5rem' } // Responsive font size
                }}
            >
                Gestión de Perfiles de Mascotas
            </Typography>
            {pets.map((pet, index) => (
                <MuiAccordion
                    key={pet.petId}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    sx={{ mb: 1 }} // Add bottom margin for spacing

                >
                    <MuiAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Avatar
                            src={editedData[index]?.imageUrl || pet.imageUrl}
                            sx={{ width: 56, height: 56, mr: 2 }}
                        />
                        {!expanded && (
                            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Responsive font size
                                    }}
                                >
                                    {pet.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'text.secondary',
                                        textAlign: 'left', // Align to the left
                                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, // Responsive font size
                                    }}
                                >
                                    Presiona para ver más detalles
                                </Typography>
                            </Box>
                        )}
                        {/* <Typography>{pet.name}</Typography> */}
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                        <ThemeProvider theme={createTheme()}>

                            <Grid container component="main" >
                                <CssBaseline />
                                {/* Background Image Grid */}

                                {/* Form Grid */}
                                <Grid item xs={12} sm={8} md={12} component={Paper} elevation={6} square>

                                    <Box sx={{ my: 5, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}> {/* Adjusted margin top */}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: 'black', // Adjust the color as needed
                                                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' } // Responsive font size
                                                }}
                                            >
                                                {pet.name}
                                            </Typography>
                                        </Box>


                                        {/* Pet ID Display */}
                                        <Box component="form" noValidate onSubmit={(e) => {
                                            const petIdToPass = pet.petId;
                                            const petPhone = pet.phone;
                                            console.log(`Pet to be submitted: ${pet}`);

                                            handleSubmit(index, petIdToPass, petPhone, e);
                                        }}
                                            sx={{ mt: 0, display: 'flex', flexDirection: 'column', width: '100%' }}>

                                            <Grid container spacing={2}>

                                                {/* Left Column */}
                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        freeSolo
                                                        options={commonBreeds}
                                                        value={editedData[index]?.race !== undefined ? editedData[index].race : pet.race}
                                                        onInputChange={(event, newInputValue) => {
                                                            // Handle changes for free text input
                                                            handleInputChange(index, { target: { name: 'race', value: newInputValue } });
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            // Handle changes for selected dropdown options
                                                            if (typeof newValue === 'string') {
                                                                handleInputChange(index, { target: { name: 'race', value: newValue } });
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                name="race"
                                                                label="Raza"
                                                            />
                                                        )}
                                                    />

                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="age"
                                                        label="Edad"
                                                        value={editedData[index]?.age !== undefined ? editedData[index].age : pet.age} onChange={(e) => handleInputChange(index, e)}
                                                    />
                                                </Grid>
                                                {/* Right Column */}
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        name="location"
                                                        label="Ubicación"
                                                        value={editedData[index]?.location !== undefined ? editedData[index].location : pet.location}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                    />


                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        name="phone"
                                                        label="Teléfono de contacto (Sólo lectura)"
                                                        value={editedData[index]?.phone !== undefined ? editedData[index].phone : pet.phone}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Este campo no se puede editar">
                                                                        <LockIcon /> {/* Using the lock icon */}
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        name="secondaryPhone"
                                                        label="Teléfono secundario"
                                                        value={editedData[index]?.secondaryPhone !== undefined ? editedData[index].secondaryPhone : pet.secondaryPhone}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                    />

                                                </Grid>
                                            </Grid>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                name="description"
                                                label="Cuéntanos algo especial sobre él/ella?"
                                                multiline rows={4}
                                                value={editedData[index]?.description !== undefined ? editedData[index].description : pet.description} onChange={(e) => handleInputChange(index, e)}
                                            />

                                            <MemoizedFilePond
                                                handleUploadedFile={(file) => handleFileChange(file, index, 'image')}
                                                label="Selecciona la foto de tu mascota"
                                                sx={{ mt: 3, mb: 10 }}
                                            />
                                            <hr />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="ruac"
                                                label="RUAC (Clave Única para Animales de Compañía)"
                                                value={editedData[index]?.ruac || ''}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {editedData[index]?.ruacUrl && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img
                                                        src={editedData[index].ruacUrl}
                                                        alt="RUAC Document"
                                                        style={responsiveImageStyle}
                                                    />
                                                </Box>
                                            )}
                                            <MemoizedFilePond
                                                handleUploadedFile={(file) => handleFileChange(file, index, 'ruac')}
                                                label="Selecciona la foto del RUAC"
                                                sx={{ mt: 3, mb: 2 }}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={editedData[index]?.enabled !== undefined ? editedData[index].enabled : pet.enabled}
                                                        onChange={(e) => handleInputChange(index, { target: { name: 'enabled', value: e.target.checked } })}
                                                        name="enabled"
                                                    />
                                                }
                                                label="Habilitar envío de ubicación por SMS"
                                                sx={{ mt: 2 }}
                                            />

                                            {error && (
                                                <Alert variant="danger" className="mt-2">
                                                    Ocurrió un error, intentar más tarde.
                                                </Alert>
                                            )}
                                            {alertVisible && (
                                                <Grid item xs={12}>
                                                    <Alert severity="success">
                                                        Información actualizada correctamente.
                                                    </Alert>
                                                </Grid>
                                            )}
                                            <Button type="submit" fullWidth variant="contained" style={{
                                                backgroundColor: '#c39ec6', // Soft purple background
                                                color: 'white', // White text
                                                borderColor: '#c39ec6', // Soft purple border

                                            }} sx={{ mt: 3, mb: 2 }} disabled={isLoading} >{isLoading ? <Spinner animation="border" size="sm" /> : "Guardar"}</Button>

                                            <Grid container justifyContent="flex-end">
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ThemeProvider>

                    </MuiAccordionDetails>

                </MuiAccordion>
            ))}
        </Box>
    );
};


const PasswordChangeComponent = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phoneOwner = searchParams.get('phone');
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMsg, setPasswordMsg] = useState(null);
    const [loading, setLoading] = useState(false);  // Loading state
    const [activeKey, setActiveKey] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    let msgTimeout;

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setPasswordMsg("Las contraseñas nuevas no coinciden!");
            if (msgTimeout) {
                clearTimeout(msgTimeout);
            }
            msgTimeout = setTimeout(clearPasswordMsg, 4000);
            return;
        }
        setLoading(true);

        const endpoint = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-change-password';
        const payload = {
            queryStringParameters: {
                action: "change_password"
            },
            body: JSON.stringify({
                phone: phoneOwner,
                oldPassword: currentPassword,
                newPassword: newPassword
            })
        };

        try {
            const response = await axios.post(endpoint, payload);

            if (response.data.statusCode === 200) {
                setPasswordMsg("Contraseña cambiada con éxito!");
            } else {
                setPasswordMsg("La contraseña anterior que ingresaste es incorrecta. Por favor, verifica e inténtalo de nuevo.");
            }
            if (msgTimeout) {
                clearTimeout(msgTimeout);
            }
            msgTimeout = setTimeout(clearPasswordMsg, 4000);
        } catch (error) {
            setPasswordMsg("La contraseña anterior que ingresaste es incorrecta. Por favor, verifica e inténtalo de nuevo.");
        }

        setLoading(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const clearPasswordMsg = () => {
        setPasswordMsg(null);
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Mándanos un email a nuestro correo de soporte o a alguno de nuestros id lovers de soporte al número 5533451423.
        </Tooltip>
    );
    return (
        <Box sx={{ mt: 4, mx: 4 }}>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    mb: 3,
                    textAlign: 'center',

                    fontSize: { xs: '1.5rem', sm: '2rem', md: '1.5rem' } // Responsive font size
                }}
            >
                Cambiar contraseña
            </Typography>
            <form onSubmit={handlePasswordChange}>
                <TextField
                    label="Contraseña actual"
                    type={showPassword ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Nueva contraseña"
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Confirmar nueva contraseña"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {passwordMsg && (
                    <Alert
                        severity={passwordMsg.includes("éxito") ? "success" : "error"}
                        sx={{ mt: 2 }}
                    >
                        {passwordMsg}
                    </Alert>
                )}
                <Button type="submit" fullWidth variant="contained" style={{
                    backgroundColor: '#c39ec6', // Soft purple background
                    color: 'white', // White text
                    borderColor: '#c39ec6', // Soft purple border

                }} sx={{ mt: 3, mb: 2 }} disabled={loading} >{loading ? <Spinner animation="border" size="sm" /> : "Cambiar contraseña"}</Button>

                <Tooltip title="Haz clic aquí si has olvidado tu contraseña">
                    <IconButton >
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </form>
        </Box>
    );

};

export default Profile;
