import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Added useNavigate to imports
import { useAuth } from '../../AuthContext'; // Adjust path based on your directory structure
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../../images/logo.png';
import gigi from '../../images/gigi2.jpeg';
import { Spinner, Alert } from 'react-bootstrap';
import ForgotPasswordDialog from './ForgotPasswordDialog'; // Import the new component
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const defaultTheme = createTheme();

const Login = () => {

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();
    const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        const onlyNumbers = /^[0-9]*$/;

        setPhoneNumber(value);

        if (!onlyNumbers.test(value) || value.length !== 10) {
            setPhoneError('Ingresa los 10 dígitos de tu número.');
        } else {
            setPhoneError('');
        }
    };


    const handleForgotPasswordOpen = () => {
        setIsForgotPasswordOpen(true);
    };

    const handleForgotPasswordClose = () => {
        setIsForgotPasswordOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const phone = data.get('phone');
        const password = data.get('password');

        console.log({
            email: data.get('phone'),
            password: data.get('password'),
        });
        event.preventDefault();
        setLoading(true);
        setError(false);

        const payload = {
            queryStringParameters: {
                action: "login"
            },
            body: JSON.stringify({ phone, password })
        };
        console.log("Payload login ", payload);
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/idlover-login', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("Response login ", response);

            if (response.status === 200) {
                const responseBody = await response.json();
                console.log("Parsed Response Body: ", responseBody);

                // Check if responseBody has a statusCode attribute and if it's an error code
                if (responseBody.statusCode && responseBody.statusCode !== 200) {
                    if (responseBody.statusCode === 401) {
                        setError(true);
                        return;
                    } else {
                        console.error("Received non-200 inner status code:", responseBody.statusCode);
                        setError(true);
                        return;
                    }
                }
                // Parse the nested JSON string inside the body
                try {
                    const innerBody = JSON.parse(responseBody.body);
                    const { userID } = innerBody;
                    console.log('Id from body ', userID);
                    setIsAuthenticated(true);
                    navigate(`/profile?phone=${phone}&userId=${userID}`);
                } catch (e) {
                    console.error("Error parsing inner response body: ", e);
                    setError(true);
                }

            } else {
                setError(true);
            }
        } catch (error) {
            console.error('Error during the login process', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(false);
            }, 5000); // 5000 milliseconds = 5 seconds

            return () => clearTimeout(timer); // Clear the timer when component is unmounted or error changes
        }
    }, [error]);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${gigi})`, // Use the imported image here
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} src={logo} />
                        <Typography component="h1" variant="h5">
                            Iniciar sesión
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Número de teléfono (10 dígitos)"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                error={!!phoneError}
                                helperText={phoneError}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {error && (
                                <Alert variant="danger" className="mt-2">
                                    Credenciales incorrectas. Por favor, intente nuevamente.
                                </Alert>
                            )}
                            <Button type="submit" fullWidth variant="contained" style={{
                                backgroundColor: '#c39ec6', // Soft purple background
                                color: 'white', // White text
                                borderColor: '#c39ec6', // Soft purple border

                            }} sx={{ mt: 3, mb: 2 }} disabled={loading} >{loading ? <Spinner animation="border" size="sm" /> : "Log in"}</Button>

                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" onClick={handleForgotPasswordOpen}>
                                        Olvidaste tu contraseña?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        title="Para realizar el registro, tienes que adquirir una placa inteligente en una de nuestras tiendas o en nuestra página www.idlover.com"
                                      
                                    >
                                        <Link href="#" variant="body2" >
                                            <Typography variant="h7">

                                                Registro
                                            </Typography>
                                        </Link>

                                        <InfoOutlinedIcon color="primary" />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ForgotPasswordDialog open={isForgotPasswordOpen} onClose={handleForgotPasswordClose} />
        </ThemeProvider>
    );
};

export default Login;



