import React from 'react';
import { Box, Card, CardContent, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import logo from '../../images/logo.png'; // Make sure the path is correct
import logoWhatsaPP from '../../images/whatsapp.png';
import logoFacebook from '../../images/facebook.png'; // Make sure the path is correct
import logoInstagram from '../../images/instagram.png'; // Make sure the path is correct

function SupportCard() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card
            sx={{
                width: isMobile ? '100%' : '50%', // Adjusts width based on screen size
                boxShadow: 'lg',
                mt: 4,
                mx: 'auto', // Centers the card
                textAlign: 'center',
            }}
        >
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
                <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mb: 2 }}>
                    ¿Necesitas ayuda?
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    Si tienes preguntas o necesitas soporte, contáctanos a través de nuestras redes sociales o WhatsApp. Nuestro tiempo de respuesta es menor a 1 hora.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    {/* WhatsApp Icon */}
                    <IconButton
                        component="a"
                        href="https://wa.me/525643251394"
                        target="_blank"
                    >
                        <Avatar src={logoWhatsaPP} sx={{ width: 24, height: 24 }} />
                    </IconButton>

                    {/* Facebook Icon */}
                    <IconButton
                        component="a"
                        href="https://www.facebook.com/profile.php?id=61552025627308"
                        target="_blank"
                    >
                        <Avatar src={logoFacebook} sx={{ width: 24, height: 24 }} />
                    </IconButton>

                    {/* Instagram Icon */}
                    <IconButton
                        component="a"
                        href="https://www.instagram.com/idlover.shop/"
                        target="_blank"
                    >
                        <Avatar src={logoInstagram} sx={{ width: 24, height: 24 }} />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
}


export default SupportCard;
