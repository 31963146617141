import React from "react";
import './Footer.css';

const Footer = () => (
    <footer className="custom-footer">
        <div className="container text-center text-md-left">
            <div className="row">
                <div className="col-md-4 mt-md-0 mt-3">
                    <h5>Acerca de Nosotros</h5>
                    <p>Dedicados a la seguridad y bienestar de tu mascota. Siempre comprometidos con nuestra comunidad. Hecho con amor y dedicación en el corazón de México. 🇲🇽❤️</p>
                </div>

                <hr className="clearfix w-100 d-md-none pb-3" />

                <div className="col-md-4 mb-md-0 mb-3">
                    <h5>Oficinas administrativas</h5>
                    <ul className="list-unstyled">
                        <li>Paseos de Taxqueña, Coyoacán</li>
                        <li>04250</li>
                        <li>Ciudad de México, CDMX</li>
                    </ul>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                    <h5>Contacto</h5>
                    <ul className="list-unstyled">
                        <li>Teléfono: 5533451423</li>
                        <li>Email: <a href="mailto:idlover.shop@gmail.com">idlover.shop@gmail.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="footer-copyright text-center py-3">©2023 por ID-Lover</div>
    </footer>
);

export default Footer;
