import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Spinner, Alert } from 'react-bootstrap';
import logo from '../../images/logo.png';
import gigi from '../../images/gigi2.jpeg';
import MemoizedFilePond from '../FilePond/MemorizedFilePond';
import { Autocomplete } from '@mui/material';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
const URL_CREATE_PETS = ' https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-create';
const commonBreeds = [
  "Labrador Retriever",
  "Pastor Alemán",
  "Golden Retriever",
  "Bulldog",
  "Beagle",
  "Poodle",
  "Rottweiler",
  "Boxer",
  "Dachshund",
  "Siberian Husky",
  "Yorkshire Terrier",
  "Pitbull",
  "Chihuahua",
  "Border Collie",
  "Pastor Australiano",
  "Cocker Spaniel",
  "Bichon Frise",
  "Doberman",
  "Shih Tzu",
  "Gran Danés",
  "Mastín Napolitano",
  "San Bernardo",
  "Cavalier King Charles Spaniel",
  "Shar Pei",
  "Akita Inu",
  "Bulldog Francés",
  "Dálmata",
  "Galgo",
  "Jack Russell Terrier",
  "Maltese",
  "Pomerania",
  "Samoyedo",
  "Shiba Inu",
  "Staffordshire Bull Terrier",
  "Weimaraner",
  "West Highland White Terrier",
  "Basset Hound",
  "Boston Terrier",
  "Braco Alemán",
  "Bull Terrier",
  "Cane Corso",
  "Carlino",
  "Chow Chow",
  "Fox Terrier",
  "Pinscher",
  "Pointer",
  "Setter Irlandés",
  "Teckel",
  "Schnauzer",
  "Terranova",
  "Basenji",
  "Presa Canario",
  "Saluki",
  "Perro de Agua Español",
  "Papillon",
  "Keeshond",
  "Boyero de Berna",
  "Airedale Terrier",
  "Whippet",
  "Lhasa Apso",
  "Rhodesian Ridgeback",
  "Puli",
  "Pekinés",
  "Vizsla",
  "Scottish Terrier",
  "Cairn Terrier",
  "Clumber Spaniel",
  "Norfolk Terrier"
];


function RegistrationForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const petId = searchParams.get('petId');

  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [petNameError, setPetNameError] = useState('');
  const [ageError, setAgeError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [imageError, setImageError] = useState('');

  const validateForm = (formData) => {
    let isValid = true;

    // Reset errors
    setPetNameError('');
    setAgeError('');
    setPhoneError('');
    setImageError('');

    // Validate Pet Name
    if (!formData.get('petName').trim()) {
      setPetNameError('El nombre de la mascota es obligatorio.');
      isValid = false;
    }

    // Validate Age
    if (!formData.get('age')) {
      setAgeError('La edad es obligatoria.');
      isValid = false;
    }

    // Validate Phone Number
    if (!formData.get('phoneNumber').trim() || formData.get('phoneNumber').trim().length !== 10) {
      setPhoneError('Ingresa los 10 dígitos de tu número.');
      isValid = false;
    }

    // Validate Image Upload
    if (!uploadedFile) {
      setImageError('La foto de la mascota es obligatoria.');
      isValid = false;
    }

    return isValid;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);

    const formData = new FormData(event.currentTarget);

    if (!validateForm(formData)) {
      setLoading(false);
      return;
    }
    // Capture form data
    const petData = {
      petId: petId,
      name: formData.get('petName').trim(),
      race: formData.get('breed').trim(),
      age: formData.get('age').trim(),
      phone: formData.get('phoneNumber').trim(),
      location: formData.get('home').trim(),
      description: formData.get('specialAboutPet').trim(),
    };


    // Include the image file if uploaded
    if (uploadedFile) {
      console.log('Contains image data front end');
      console.log(uploadedFile);
      petData.imageData = await convertFileToBase64(uploadedFile);
    }

    // Construct the API request payload
    const requestBody = {
      queryStringParameters: {
        action: "create_from_user"
      },
      body: JSON.stringify(petData)
    };

    try {
      console.log('Create from user body ', requestBody);
      // Make the API request
      const response = await fetch(URL_CREATE_PETS, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      // Handle response
      const responseData = await response.json();
      if (response.ok) {
        setLoading(false);
        navigate(`/petInformation?petId=${petId}`);
        console.log("Pet data successfully sent:", responseData);
      } else {
        setLoading(false);
        setError(true);
        console.error("Error sending pet data:", responseData);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.error("An error occurred while sending the pet data:", error);
    } finally {
      setLoading(false);
      setUploadedFile(null);
    }
  };

  const handleFileChange = (file) => {
    setUploadedFile(file);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const onlyNumbers = /^[0-9]*$/;

    // Update phone number state with every change
    setPhoneNumber(value);

    // Set or reset error message based on input validity
    if (!onlyNumbers.test(value) || value.length !== 10) {
      setPhoneError('Ingresa los 10 dígitos de tu número.');
    } else {
      setPhoneError(''); // Reset error message when input is valid
    }
  };

  // Helper function to convert file to Base64
  const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(timer); // Clear the timer when component is unmounted or error changes
    }
  }, [error]);

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        {/* Background Image Grid */}
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${gigi})`, // Use the imported image here
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }} />
        {/* Form Grid */}
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 130, height: 130 }} src={logo} />
            <Typography component="h1" variant="h5">Registro</Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} sm={6}>
                  <TextField margin="normal" required fullWidth name="petName" label="Nombre de tu mascota" id="petName" autoFocus error={!!petNameError}
                    helperText={petNameError} />
                  {/* Raza with Autocomplete */}
                  <Autocomplete
                    freeSolo // Allows free text input
                    options={commonBreeds} // Array of options
                    renderInput={(params) => (
                      <TextField {...params} margin="normal" fullWidth name="breed" label="Raza (Opcional)" id="breed" />
                    )}
                  />
                </Grid>
                {/* Right Column */}
                <Grid item xs={12} sm={6}>
                  <TextField margin="normal" required fullWidth name="age" label="Edad" id="age"  error={!!ageError}
                    helperText={ageError} />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phoneNumber"
                    label="Teléfono +52"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    error={!!phoneError}
                    helperText={phoneError}
                  />
                </Grid>
              </Grid>
              <TextField margin="normal" fullWidth name="home" label="Hogar de tu mascota (Opcional)" id="home" />
              <TextField margin="normal" fullWidth name="specialAboutPet" label="Cuéntanos algo especial sobre él/ella?" id="specialAboutPet" multiline rows={4} />

              <MemoizedFilePond handleUploadedFile={handleFileChange} />
              <hr />
              {error && (
                <Alert variant="danger" className="mt-2">
                  Ocurrió un error, intentar más tarde.
                </Alert>
              )}
              {/* Aviso de Privacidad - Responsive */}
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2, mb: 2, fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>
                    Al hacer clic en Continuar, aceptas nuestros
                    <Link href="https://id-lover.s3.amazonaws.com/Legal/terminos_y_condiciones.pdf" target="_blank" rel="noopener noreferrer">
                      Términos y Condiciones
                    </Link>
                    y nuestro Aviso de Privacidad. Puedes recibir notificaciones nuestras y tienes el derecho de cancelarlas en cualquier momento.
                  </Typography>
                </Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" style={{
                backgroundColor: '#c39ec6', // Soft purple background
                color: 'white', // White text
                borderColor: '#c39ec6', // Soft purple border

              }} sx={{ mt: 3, mb: 2 }} disabled={loading || !uploadedFile} >{loading ? <Spinner animation="border" size="sm" /> : "Registro"}</Button>

              <Grid container justifyContent="flex-end">
                <Grid item>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
export default RegistrationForm;