import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { MDBIcon, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';


import './PetProfile.css';

const URL_RETRIEVE_PETS = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-retrieve';
const URL_RETRIEVE_PET_BY_ID = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-get-by-pet-id';
const URL_SEND_LOCATION = 'https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-send-location';

const ERROR_MESSAGE = "¡Vaya! No pudimos encontrar información sobre la mascota que estás buscando. Por favor, asegúrate de que el enlace es correcto.";
// Responsible for displaying the pets information if exists otherwise the Pet Form registration
const PetProfile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const phoneOwner = searchParams.get('phone');
    const petId = searchParams.get('petId');

    const [petData, setPetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        console.log("In Pet Profile");
        if (petId && phoneOwner) {
            retrievePetData();
        } else {
            retrievePetDataByPetId();
        }
    }, [petId]);

    useEffect(() => {
    }, [petData]);

    const sendCurrentLocation = () => {
        console.log("sendCurrentLocation: Method initiated."); // Log 1: Method initiation

        if (navigator.geolocation) {
            console.log("sendCurrentLocation: Geolocation available. Attempting to get current position."); // Log 2: Check if geolocation is available

            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;

                console.log(`sendCurrentLocation: Successfully obtained location. Latitude: ${latitude}, Longitude: ${longitude}`); // Log 3: Successful geolocation retrieval

                // Prepare payload to send to the endpoint
                const payload = {
                    queryStringParameters: {
                        action: "location",
                        latitude: latitude,
                        longitude: longitude,
                        petId: petId,
                        phone: phoneOwner
                    }
                };

                console.log(`sendCurrentLocation: Preparing to send location to API endpoint with payload ${JSON.stringify(payload)}`); // Log 4: Before sending data to API

                try {
                    const response = await fetch(URL_SEND_LOCATION, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload)
                    });

                    const responseData = await response.json();
                    console.log("sendCurrentLocation: Location sent successfully:", responseData); // Log 5: Successful API response
                } catch (error) {
                    console.error("sendCurrentLocation: Error sending location:", error); // Log 6: Error in API request
                }

            }, error => {
                console.error("sendCurrentLocation: Error obtaining location", error); // Log 7: Error in geolocation retrieval
            });
        } else {
            console.error("sendCurrentLocation: Geolocation is not supported by this browser."); // Log 8: Geolocation not supported
        }
    };

    const retrievePetDataByPetId = async () => {
        console.log("Retrieving by petId ", petId);
        try {
            const bodyPayLoad = JSON.stringify({
                queryStringParameters: {
                    action: 'getByPetId',
                    petId: petId,
                }
            });

            const response = await fetch(URL_RETRIEVE_PET_BY_ID, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: bodyPayLoad
            });

            const { body } = await response.json();
            console.log("Body response from getById ", body);
            if (body) {
                const { name, imageUrl, phone } = JSON.parse(body);
                if (phone === "NO_PHONE") {
                    console.log("Navigating to Form");
                    navigate(`/form?petId=${petId}`);

                }
                if (name && imageUrl) {
                    sendCurrentLocation();
                    setPetData({ name, imageUrl, ...JSON.parse(body) });
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            setErrorMsg(ERROR_MESSAGE);
        } finally {
            setLoading(false);
        }
    };
    const retrievePetData = async () => {
        try {
            const bodyPayLoad = JSON.stringify({
                queryStringParameters: {
                    action: 'retrieve',
                    petId: petId,
                    phone: phoneOwner
                }
            });

            const response = await fetch(URL_RETRIEVE_PETS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: bodyPayLoad
            });

            const { body } = await response.json();

            if (body) {
                const { name, imageUrl } = JSON.parse(body);

                if (name && imageUrl) {
                    sendCurrentLocation();
                    setPetData({ name, imageUrl, ...JSON.parse(body) });
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            setErrorMsg(ERROR_MESSAGE);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <LoadingComponent />;
    if (!petData || errorMsg) return <ErrorComponent errorMsg={errorMsg} />;

    return <PetProfileDisplay petData={petData} />;
};

const LoadingComponent = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner animation="border" role="status"></Spinner>
    </div>
);

const ErrorComponent = ({ errorMsg }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="warning" style={{ textAlign: 'center', maxWidth: '400px' }}>
            <Alert.Heading>¡Oh no!</Alert.Heading>
            <p>{errorMsg}</p>
        </Alert>
    </div>
);

const PetProfileDisplay = ({ petData }) => {
    return (
        <div className="gradient-custom-2" style={{ backgroundColor: '#9de2ff' }}>
            <MDBContainer className="py-5 h-100">
                <MDBRow className="justify-content-center align-items-center h-100">
                    <MDBCol lg="9" xl="7">
                        <MDBCard>
                            <div className="rounded-top text-white d-flex flex-row" style={{ backgroundColor: '#000', height: '200px' }}>
                                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                                    <MDBCardImage src={petData.imageUrl}
                                        alt="Mascota" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} />

                                </div>
                                <div className="ms-3" style={{ marginTop: '130px' }}>
                                    <MDBTypography tag="h5">{petData.name}</MDBTypography>
                                    <MDBCardText>{petData.race}</MDBCardText>
                                </div>
                            </div>
                            <div className="p-4 text-black" style={{ backgroundColor: '#f8f9fa' }}>
                                <div className="d-flex justify-content-end text-center py-1">
                                    <div>
                                        <MDBCardText className="mb-1 h5">{petData.age}</MDBCardText>
                                        <MDBCardText className="small text-muted mb-0">Edad</MDBCardText>
                                    </div>
                                </div>
                            </div>
                            <MDBCardBody className="text-black p-4">
                                <div className="mb-5">
                                    <p className="lead fw-normal mb-1">Información</p>
                                    <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                                        <MDBCardText className="font-italic mb-1">{petData.description}</MDBCardText>
                                        {petData.description && (<MDBCardText className="font-italic mb-1">Vivo en {petData.location}</MDBCardText>)}
                                    </div>
                                </div>

                                <a href={`tel:+52${petData.phone}`} style={{ textDecoration: 'none' }}>
                                    <Button className="randomizeButton"
                                        style={{
                                            backgroundColor: '#c39ec6', // Soft purple background
                                            color: 'white', // White text
                                            borderColor: '#c39ec6', // Soft purple border
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            width: '100%'
                                        }}>
                                        <MDBIcon icon="phone" /> Llamar a mi dueño
                                    </Button>
                                </a>
                                <hr />
                                {petData.secondaryPhone &&
                                    <a href={`tel:+52${petData.secondaryPhone}`} style={{ textDecoration: 'none' }}>
                                        <Button className="randomizeButton"
                                            style={{
                                                backgroundColor: '#c39ec6', // Soft purple background
                                                color: 'white', // White text
                                                borderColor: '#c39ec6', // Soft purple border
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                                width: '100%'
                                            }}>
                                            <MDBIcon icon="phone" /> Llamar a mi número secundario
                                        </Button>
                                    </a>
                                }

                                {petData.ruacUrl && (
                                    <>
                                        <hr />
                                        <div className="text-center">
                                            <p className="lead fw-normal mb-1">Documento RUAC</p>
                                            <img
                                                src={petData.ruacUrl}
                                                alt="RUAC Document"
                                                style={{
                                                    maxWidth: '100%',
                                                    height: 'auto',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
};
export default PetProfile;
