import React from 'react';
import './App.css';
import PetProfile from './components/PetProfile/PetProfile';
import Login from './components/Login/Login';
import Profile from './components/Profile/Profile'; 
import RegistrationForm from './components/Form/RegistrationForm'; 
import PetDetailedInfo from './components/Profile/PetDetailedInfo';
import { AuthProvider } from './AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate // Import the Navigate component for redirection
} from 'react-router-dom';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} /> {/* Default route redirects to /login */}
          <Route path="/petInformation" element={<PetProfile />} />
          <Route path="/petDetailedInfo" element={<PetDetailedInfo />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/form" element={<RegistrationForm />} />
          <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect unmatched paths to login */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
