import React from 'react';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { darken } from '@mui/system';
import { styled } from '@mui/system';

const StyledFilePond = styled(FilePond)(({ theme }) => ({
    background: darken(theme.palette.background.default, 0.05),
    borderStyle: 'dotted',
    borderWidth: '3px',
    borderColor: '#ccc',
    borderRadius: '12px',
    '&:hover': {
        borderColor: '#333',
    },
    '& .filepond--panel-root': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    '& .filepond--label-action': {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    '& .filepond--icon': {
        fontSize: '48px',
        marginBottom: theme.spacing(1),
    },
}));

const MemoizedFilePond = ({ handleUploadedFile, label = 'Selecciona la foto de tu mascota' }) => {
    return (
        <StyledFilePond
            labelIdle={label}
            required
            stylePanelLayout="integrated"
            acceptedFileTypes={['image/*']}
            allowImagePreview
            allowImageExifOrientation
            onupdatefiles={(fileItems) => handleUploadedFile(fileItems[0]?.file)}
        />
    );
};

export default React.memo(MemoizedFilePond);
