import React, { useState, useEffect } from 'react';
import {isWithinInterval, addYears } from 'date-fns';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Chip, Fab, DialogContentText, FormControl, InputLabel, Select, MenuItem, useMediaQuery, useTheme, IconButton, Grid } from '@mui/material';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AlarmIcon from '@mui/icons-material/Alarm';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import VaccineIcon from '@mui/icons-material/LocalHospital';
import GroomingIcon from '@mui/icons-material/ContentCut';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ShowerIcon from '@mui/icons-material/Shower';
import PetsIcon from '@mui/icons-material/Pets';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Spinner, Alert } from 'react-bootstrap';
import NotificationsIcon from '@mui/icons-material/Notifications'; // Import the icon
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from 'react-bootstrap/Modal';
import { es } from 'date-fns/locale';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './VaccinationRecordComponent.css';
import Tooltip from '@mui/material/Tooltip';
import { isToday, isPast, parseISO, startOfDay } from 'date-fns';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { utcToZonedTime } from 'date-fns-tz';
import Autocomplete from '@mui/material/Autocomplete';

const TIME_ZONE = 'America/Mexico_City'; // Zona horaria para CMDX

const ReminderTypeIcons = {
    vaccine: <LocalHospitalIcon />,
    grooming: <PetsIcon />,
    shower: <ShowerIcon />
};

const tiposDeRecordatorio = [
    "Vacuna", "Aseo General", "Control Veterinario", "Desparasitación",
    "Medicación", "Alimentación Especial", "Ejercicio Físico", "Entrenamiento/Adiestramiento",
    "Peluquería/Estética", "Cumpleaños", "Compra de Suministros", "Socialización/Interacción",
    "Visita al Parque", "Revisión Dental", "Corte de Uñas", "Baño Medicado"
];

function VaccinationRecordComponent({ userId, phoneOwner }) {
    const [open, setOpen] = useState(false);
    const [selectedVaccine, setSelectedVaccine] = useState('');
    const [reminderDate, setReminderDate] = useState('');
    const [newReminderOpen, setNewReminderOpen] = useState(false);
    const [newVaccineName, setNewVaccineName] = useState('');
    const [newVaccineDate, setNewVaccineDate] = useState('');
    const [newReminderType, setNewReminderType] = useState('Vacuna');
    const [newReminderDate, setNewReminderDate] = useState(new Date()); // Default to today's date
    const [comment, setComment] = useState('');
    const [selectedPet, setSelectedPet] = useState('');
    const [reminders, setReminders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [phone, setPhone] = useState(phoneOwner);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteReminderId, setDeleteReminderId] = useState(null);
    const [pets, setPets] = useState([]); // State to hold the list of pets

    // Update
    const [updateReminderOpen, setUpdateReminderOpen] = useState(false);
    const [updatingReminderId, setUpdatingReminderId] = useState(null);
    const [updatingReminderType, setUpdatingReminderType] = useState('');
    const [updatingReminderDate, setUpdatingReminderDate] = useState(new Date());
    const [updatingComment, setUpdatingComment] = useState('');


    const determineChipColor = (date, frequency) => {
        const today = new Date();
        const dueDate = addYears(new Date(date), frequency === 'Annual' ? 1 : 2);
        if (isPast(dueDate)) return 'error';
        if (isWithinInterval(today, { start: new Date(dueDate), end: addYears(new Date(dueDate), 1) })) return 'warning';
        return 'success';
    };

    const handleClickOpen = (vaccineName) => {
        setOpen(true);
        setSelectedVaccine(vaccineName);
    };

    const handleClose = () => setOpen(false);

    const handleDateChange = (newValue) => {
        // Convertir la fecha seleccionada a UTC basándonos en la zona horaria de CMDX
        const zonedDateStart = startOfDay(utcToZonedTime(newValue, TIME_ZONE));
        console.log('Zoned date start of day: ', zonedDateStart);
        setNewReminderDate(zonedDateStart);
    };

    const handleDateChangeUpdate = (newValue) => {
        // Ajustar la fecha seleccionada al comienzo del día en la zona horaria de CMDX
        const zonedDateStart = startOfDay(utcToZonedTime(newValue, TIME_ZONE));
        console.log('Zoned date start of day: ', zonedDateStart);
        setUpdatingReminderDate(zonedDateStart);
    };

    const setReminder = () => {
        console.log(`Recordatorio configurado para ${selectedVaccine} en:`, reminderDate);
        handleClose();
    };

    const handleNewReminderOpen = () => {
        setNewReminderOpen(true);
    };

    const handleNewReminderClose = () => {
        setNewReminderOpen(false);
    };

    const addNewReminder = async () => {
        // Find the selected pet details from the pets array
        const selectedPetDetails = pets.find(pet => pet.name === selectedPet);

        if (!selectedPetDetails) {
            console.error("Pet not found");
            setError("Pet not found");
            return;
        }

        const petId = selectedPetDetails.petId; // Assuming each pet object has an id field
        const imageUrl = selectedPetDetails.imageUrl; // Assuming each pet object has an imageUrl field

        const reminderData = {
            petName: selectedPet,
            reminderType: newReminderType,
            reminderDate: format(newReminderDate, 'yyyy-MM-dd'), // Format the date to YYYY-MM-DD
            comments: comment,
            petId: petId,
            userId: userId,
            reminderName: `${selectedPet}`, // Construct a name for the reminder
            imageUrl: imageUrl
        };
        console.log('Request body ', reminderData);
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-reminders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "create_reminder",
                    },
                    body: JSON.stringify(reminderData)
                })
            });

            if (!response.ok) {
                setError("Error al crear el recordatorio.");
            }

            const responseBody = await response.json();
            console.log("New reminder response:", responseBody);
            fetchReminders(); // Fetch reminders again to update the list
            handleNewReminderClose(); // Close the dialog

            setSelectedPet('');
            setNewReminderType('');
            setNewReminderDate(null); // O la fecha inicial que desees
            setComment('');

            fetchReminders(); // Actualiza la lista de recordatorios
            handleNewReminderClose();
        } catch (error) {
            console.error("Error creating new reminder:", error);
            setError("Error al crear el recordatorio.");
        }
    };


    const getServiceIcon = (type) => {
        switch (type) {
            case 'Vaccine': return <VaccineIcon />;
            case 'grooming': return <GroomingIcon />;
            case 'shower': return <ShowerIcon />;
            default: return <VaccineIcon />;
        }
    };

    const fetchReminders = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-reminders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "get_reminders_by_user_id",
                        userId: userId
                    }
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseBody = await response.json();
            console.log('Response body ', responseBody);

            if (responseBody && responseBody.body) {
                let outerParsedBody;
                try {
                    outerParsedBody = JSON.parse(responseBody.body); // First level of parsing
                    console.log('outerParsedBody', outerParsedBody);
                } catch (error) {
                    console.error("Error in outer parsing:", error);
                    setError("Error al procesar los datos recibidos.");
                    return;
                }

                if (outerParsedBody && outerParsedBody.reminders) {
                    let innerParsedBody;
                    try {
                        innerParsedBody = JSON.parse(outerParsedBody.reminders.body); // Second level of parsing
                        console.log('innerParsedBody', innerParsedBody);
                    } catch (error) {
                        console.error("Error in inner parsing:", error);
                        setError("Error al procesar los recordatorios.");
                        return;
                    }

                    if (innerParsedBody && Array.isArray(innerParsedBody.reminders)) {
                        console.log('reminders', innerParsedBody.reminders);
                        setReminders(innerParsedBody.reminders);
                    } else {
                        setError("No se pudieron obtener los recordatorios, intentar más tarde.");
                    }
                } else {
                    setError("No se pudieron obtener los recordatorios, intentar más tarde.");
                }
            } else {
                setError("No se pudieron obtener los recordatorios, intentar más tarde.");
            }
        } catch (error) {
            console.error("Fetching reminders failed:", error);
            setError("No se pudieron obtener los recordatorios, intentar más tarde.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteReminder = (reminderId) => {
        setDeleteReminderId(reminderId);
        setShowDeleteModal(true);
    };

    const confirmDeleteReminder = async () => {
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-reminders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "delete_reminder",
                        reminderId: deleteReminderId
                    }
                })
            });

            if (!response.ok) {
                setError("No se pudo eliminar tu recordatorio, intentar más tarde");
            }

            const responseBody = await response.json();
            console.log("Deleted reminder response:", responseBody);
            setShowDeleteModal(false);
            fetchReminders(); // Fetch reminders again to update the list
        } catch (error) {
            console.error("Error deleting reminder:", error);
            setError("Error al eliminar el recordatorio.");
        }
    };

    const fetchPets = async () => {
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/get-pets-from-phone', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "get_pets_from_phone",
                        phone: phoneOwner
                    }
                })
            });

            if (!response.ok) {
                setError("Error al obtener la lista de mascotas, intentas más tarde");
            }

            const data = await response.json();
            const parsedData = JSON.parse(data.body); // Parse the response body
            if (parsedData && Array.isArray(parsedData.pets)) {
                setPets(parsedData.pets); // Update the state with the list of pets
            }
        } catch (error) {
            console.error("Error fetching pets:", error);
            setError("Error al obtener la lista de mascotas.");
        }
    };

    const openUpdateDialog = (reminder) => {
        setUpdateReminderOpen(true);
        setUpdatingReminderId(reminder.reminderId);
        setUpdatingReminderType(reminder.reminderType);

        // Convertir la fecha de UTC a la zona horaria local y ajustar a la medianoche
        const localDate = new Date(reminder.reminderDate + 'T00:00:00');
        setUpdatingReminderDate(localDate);

        setUpdatingComment(reminder.comments);
        console.log("Updated Date for DatePicker:", localDate);
    };

    const updateReminder = async () => {
        // Prepare the data to be sent in the request
        const reminderData = {
            reminderType: updatingReminderType,
            reminderDate: updatingReminderDate.toISOString().split('T')[0], // Format the date to YYYY-MM-DD
            comments: updatingComment
            // Add any other fields that are part of your reminder data model
        };
        setIsLoading(true);
        setUpdateReminderOpen(false); // Close the update dialog
        try {
            const response = await fetch('https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/pet-profile-reminders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    queryStringParameters: {
                        action: "update_reminder",
                        reminderId: updatingReminderId
                    },
                    body: JSON.stringify(reminderData)
                })
            });

            if (!response.ok) {
                setError("Error al actualizar el recordatorio.");
                return;
            }

            const responseBody = await response.json();
            console.log("Updated reminder response:", responseBody);
            fetchReminders(); // Fetch reminders again to update the list
            setUpdateReminderOpen(false); // Close the update dialog
        } catch (error) {
            console.error("Error updating reminder:", error);
            setError("Error al actualizar el recordatorio.");
        }
    };


    useEffect(() => {

        console.log('User id ', userId);
        console.log('Reminders', reminders);

        if (userId) {
            fetchReminders(); // Fetch reminders using the userId
        }
    }, [userId]); // Run only when userId changes

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(null);
            }, 5000); // 5000 milliseconds = 5 seconds
            return () => clearTimeout(timer); // Clear the timer when component is unmounted or error changes
        }
    }, [error]); // Run only when error changes

    useEffect(() => {
        fetchPets(); // Fetch pets when the component mounts
    }, [phoneOwner]); // Dependency array with phoneOwner to ensure it runs when phoneOwner changes


    return (
        <div style={{ maxWidth: '1000px', margin: 'auto', marginTop: '30px', marginLeft: '20px' }}>
            <Typography variant="h7" align="center" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}>
                Registro de Salud y Cuidado de Mascotas
            </Typography>
            {error && (
                <Alert variant="danger" className="mt-2">
                    {error}
                </Alert>
            )}
            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner animation="border" />
            </div> : (
                <Grid container spacing={2}>
                    {reminders && reminders.length > 0 ? (
                        reminders
                            .sort((a, b) => new Date(b.reminderDate) - new Date(a.reminderDate)) // Sort reminders by date in descending order
                            .map((reminder, index) => (
                                <Grid item xs={12} sm={6} md={9} key={index}>
                                    <Card variant="outlined" sx={{ resize: 'horizontal', overflow: 'visible' }}>
                                        <IconButton
                                            sx={{ position: 'absolute', top: 8, right: 8 }}
                                            onClick={() => handleDeleteReminder(reminder.reminderId)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                                            <Avatar src={reminder.imageUrl} size="lg" />
                                        </Box>

                                        <CardContent>
                                            <Typography level="title-lg" sx={{ mb: 1 }}>{reminder.reminderName}</Typography>

                                            <Typography level="body-sm" sx={{ mb: 1 }}>
                                                <strong>Tipo:</strong> {reminder.reminderType || 'No disponible'}
                                            </Typography>

                                            <Typography level="body-sm" sx={{ mb: 1 }}>
                                                <strong>Fecha:</strong> {
                                                    reminder.reminderDate
                                                        ? format(utcToZonedTime(parseISO(reminder.reminderDate), TIME_ZONE), 'PPPP', { locale: es })
                                                        : 'No disponible'
                                                }
                                            </Typography>

                                            {/* Chip in a separate line */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                                {reminder.reminderDate && (
                                                    <Chip
                                                        label={
                                                            isToday(parseISO(reminder.reminderDate)) || !isPast(startOfDay(parseISO(reminder.reminderDate)))
                                                                ? 'Al Día'
                                                                : 'Vencida'
                                                        }
                                                        color={
                                                            isToday(parseISO(reminder.reminderDate)) || !isPast(startOfDay(parseISO(reminder.reminderDate)))
                                                                ? 'success'
                                                                : 'error'
                                                        }
                                                        icon={
                                                            isToday(parseISO(reminder.reminderDate)) || !isPast(startOfDay(parseISO(reminder.reminderDate)))
                                                                ? <CheckCircleIcon />
                                                                : <ErrorIcon />
                                                        }
                                                    />
                                                )}
                                            </Box>

                                            <Typography level="body-sm" sx={{ mb: 1 }}>
                                                <strong>Comentarios:</strong> {reminder.comments || 'No hay comentarios'}
                                            </Typography>

                                            <Typography level="body-sm" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <NotificationsIcon fontSize="small" />
                                                Notificaciones a: {phone || 'No disponible'}
                                            </Typography>
                                        </CardContent>

                                        <CardActions sx={{ justifyContent: 'space-between', p: 2, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'stretch', sm: 'center' } }}>
                                            <Button
                                                variant="outlined"
                                                color="neutral"
                                                startIcon={<AlarmIcon />}
                                                onClick={() => openUpdateDialog(reminder)}
                                                sx={{ mb: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
                                            >
                                                Configurar
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>


                            ))
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
                                }}
                            >
                                No hay recordatorios disponibles.
                            </Typography>
                        </Box>


                    )}
                </Grid>
            )}
            <Fab
                color="secondary" // Change color for visibility
                aria-label="add"
                style={{ position: 'fixed', bottom: 20, right: 20 }}
                onClick={handleNewReminderOpen}
            >
                <Tooltip title="Añadir Nuevo Recordatorio">
                    <AddAlarmIcon className="blinking-icon" />
                </Tooltip>
            </Fab>
            <Dialog open={newReminderOpen} onClose={handleNewReminderClose} fullWidth>
                <Card variant="outlined" sx={{ maxHeight: 'max-content', maxWidth: '100%', overflow: 'auto' }}>
                    <DialogTitle>
                        <Typography level="title-lg" startDecorator={<EventIcon />}>
                            Añadir Nuevo Recordatorio
                        </Typography>
                    </DialogTitle>
                    <Divider inset="none" />
                    <DialogContent>
                        <CardContent sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 1.5 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="pet-name">Nombre de tu mascota</InputLabel>
                                <Select
                                    value={selectedPet}
                                    onChange={(e) => setSelectedPet(e.target.value)}
                                    label="Nombre de tu mascota"
                                    inputProps={{
                                        name: 'pet',
                                        id: 'pet-name',
                                    }}
                                >
                                    {pets.map(pet => (
                                        <MenuItem key={pet.id} value={pet.name}>{pet.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={newReminderType}
                                    onChange={(event, newValue) => {
                                        setNewReminderType(newValue);
                                    }}
                                    freeSolo
                                    options={tiposDeRecordatorio}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tipo de Recordatorio" />
                                    )}
                                />
                            </FormControl>

                            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Fecha del Recordatorio (MM/DD/YYY)"
                                    value={newReminderDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    format="P"
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Comentarios"
                                multiline
                                rows={4}
                                placeholder="Añade cualquier comentario adicional aquí"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                fullWidth
                            />
                        </CardContent>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="neutral" onClick={handleNewReminderClose}>
                            Cancelar
                        </Button>
                        <Button variant="solid" color="primary" onClick={() => addNewReminder(newReminderType, newReminderDate, comment, selectedPet)}>
                            Añadir Recordatorio
                        </Button>
                    </DialogActions>
                </Card>
            </Dialog>
            <Dialog open={updateReminderOpen} onClose={() => setUpdateReminderOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>Actualizar Recordatorio</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {/* Type of Reminder */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={updatingReminderType}
                                    onChange={(event, newValue) => {
                                        setUpdatingReminderType(newValue);
                                    }}
                                    freeSolo
                                    options={tiposDeRecordatorio}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tipo de Recordatorio" />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {/* Date Picker */}
                        <Grid item xs={12}>
                            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Fecha del Recordatorio (MM/DD/YYY)"
                                    value={updatingReminderDate}
                                    onChange={handleDateChangeUpdate}
                                    format="P"  // Formato de fecha, ej. 'dd/MM/yyyy'
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {/* Comments */}
                        <Grid item xs={12}>
                            <TextField
                                label="Comentarios"
                                multiline
                                rows={4}
                                value={updatingComment}
                                onChange={(e) => setUpdatingComment(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="neutral" onClick={() => setUpdateReminderOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="solid" color="primary" onClick={updateReminder}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Modal for Delete Confirmation */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Recordatorio</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de que quieres eliminar este recordatorio?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteReminder}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VaccinationRecordComponent;
